import { useEffect, useState } from "react";
import { Table } from "antd";
import type { TablePaginationConfig, TableProps } from "antd";
import { listBuyApi } from "api/purchase";
import { MoneyConvert } from "utils/format";
import dayjs from "dayjs";
import { TableParams } from "pages/types";

interface DataType {
  id: number;
  amount: string;
  inputMoney: string;
  user_address: string;
  bank_name: string;
  account_number: string;
  account_name: string;
  create_time: string;
  transaction_hash: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "생성일자",
    key: "create_time",
    dataIndex: "create_time",
    render: (_, record) => (
      <p className="table-text">
        {record.create_time.replace("T", " ").replace(".000Z", "")}
      </p>
    ),
    // textWrap: 'word-break',
    width: 180,
  },
  {
    title: "구매금액(원)",
    dataIndex: "inputMoney",
    key: "inputMoney",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.inputMoney || 0), true)}
      </p>
    ),
    width: 150,
  },
  {
    title: "구매수량(oUSDT)",
    dataIndex: "amount",
    key: "amount",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.amount || 0), true)}
      </p>
    ),
    width: 150,
  },
  {
    title: "받을 지갑 주소",
    dataIndex: "user_address",
    key: "user_address",
    render: (_, record) => <p className="table-text">{record.user_address}</p>,
  },
  {
    title: "계좌은행",
    key: "bank_name",
    dataIndex: "bank_name",
    render: (_, record) => <p className="table-text">{record.bank_name}</p>,
  },
  {
    title: "계좌번호",
    key: "account_number",
    dataIndex: "account_number",
    render: (_, record) => (
      <p className="table-text">{record.account_number}</p>
    ),
  },
  {
    title: "TXID",
    key: "TXID",
    dataIndex: "TXID",
    width: 150,
    render: (_, record) => (
      <a
        target="_blank"
        href={`https://klaytnscope.com/tx/${record.transaction_hash}?tabId=tokenTransfer`}
        className="table-text txid"
      >
        {record.transaction_hash}
      </a>
    ),
  },
  {
    title: "계좌주명",
    key: "account_name",
    dataIndex: "account_name",
    render: (_, record) => <p className="table-text">{record.account_name}</p>,
  },
];

const DataBuy = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [totalElement, setTotalElement] = useState<number>(0);

  const getDataBuy = async () => {
    try {
      const resp: any = await listBuyApi({
        page: tableParams?.pagination?.current || 1,
        perPage: tableParams?.pagination?.pageSize || 5,
        query: "",
        startTime: "",
        endTime: "",
      });
      // console.log("getDataBuy resp", resp);
      if (resp?.result) {
        const dataResp = resp?.data?.items?.map((item: any, index: number) => {
          return {
            ...item,
            id:
              index +
              1 +
              ((tableParams?.pagination?.current || 1) - 1) *
                (tableParams?.pagination?.pageSize || 5),
          };
        });
        setData(dataResp || []);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            // total: resp?.data?.pageable?.totalElement,
          },
        });
        setTotalElement(resp?.data?.pageable?.totalElement);
      }
    } catch (error) {
      console.log("getDataBuy error", error);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 5,
        },
      });
    }
  };

  useEffect(() => {
    getDataBuy();
  }, [JSON.stringify([tableParams])]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    console.log("handleTableChange", pagination?.current);

    setTableParams({
      pagination: pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content" }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: tableParams?.pagination?.pageSize,
          current: tableParams?.pagination?.current,
          total: totalElement,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default DataBuy;
