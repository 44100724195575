import styled from "styled-components";

export const Wrapper = styled.div``;

export const ModalContent = styled.div`
  padding: 0 4px;
  p {
    margin-bottom: 10px;
    line-height: 28px;
    font-size: 16px;
  }
`;

export const ModalTitle = styled.p`
  text-align: center;
  padding-top: 20px;
`;

export const ModalSubTitle = styled.p`
  text-align: center;
  font-size: 16px;
`;
