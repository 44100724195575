import styled from "styled-components";

export const Wrapper = styled.section`
  .txid {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const VerifyHistory = styled.div`
  > div {
    display: flex;
    column-gap: 6px;
    margin-bottom: 12px;
    > span {
      display: block;
      font-size: 16px;
    }
  }
`;

export const DownloadButton = styled.button`
  height: 40px;
  padding: 0 20px;
  border: 0;
  background-color: #fe8521;
  font-size: 14px;
  color: #fff;
  border-radius: 100px;
  position: absolute;
  top: 0px;
  right: 0;
  @media (max-width: 767px) {
    top: -60px;
  }
  &:focus {
    border: 0;
    outline: none;
  }
`;
